@charset "UTF-8";
/* =reset
----------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

/* =Basic structure
------------------------------------------------------------- */
html {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/* =Basic structure
------------------------------------------------------------- */
html {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-weight: normal;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', Arial, verdana, sans-serif;
  text-rendering: optimizeLegibility;
  color: #000;
}

.serif {
  font-family: 'Times New Roman', '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', '小塚明朝 ProN', 'メイリオ', Meiryo, serif;
}

a, a:link, a:active, a:visited {
  text-decoration: underline;
  outline: none;
}

a:hover {
  text-decoration: none;
}

a:focus {
  outline: none;
}

a:hover, a:active {
  outline: 0;
}

a {
  color: #21759b;
}

a:visited {
  color: #21759b;
}

a:focus {
  color: #21759b;
}

a:hover {
  color: #0f3647;
}

a:active {
  color: #21759b;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
}

img, abbr, acronym, fieldset {
  border: 0;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

dd {
  margin: 0px;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  outline: none;
  font-family: "メイリオ", "ＭＳ Ｐゴシック", sans-serif;
  font-size: 100%;
}

input, select {
  font-family: Arial, "メイリオ", "ＭＳ Ｐゴシック", sans-serif;
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
}

code,
kbd,
tt,
var,
samp,
pre {
  font-family: monospace, serif;
  font-size: 15px;
  hyphens: none;
  line-height: 1.6;
}

pre {
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 24px;
  max-width: 100%;
  overflow: auto;
  padding: 12px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

blockquote,
q {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

blockquote {
  color: #767676;
  font-size: 19px;
  font-style: italic;
  font-weight: 300;
  line-height: 1.2631578947;
  margin-bottom: 24px;
}

blockquote cite,
blockquote small {
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

blockquote em,
blockquote i,
blockquote cite {
  font-style: normal;
}

blockquote strong,
blockquote b {
  font-weight: 400;
}

small {
  font-size: smaller;
}

big {
  font-size: 125%;
}

sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  bottom: 1ex;
}

sub {
  top: .5ex;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 24px;
  padding: 11px 12px 0;
}

legend {
  white-space: normal;
}

button,
input,
select,
textarea {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

input,
textarea {
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  /* Removing the inner shadow, rounded corners on iOS inputs */
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table,
th,
td {
  border: none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 0;
  margin-bottom: 0;
  width: 100%;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: bold;
}

td {
  border-width: 0 1px 1px 0;
}

del {
  color: #767676;
}

hr {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  height: 1px;
  margin-bottom: 23px;
}

/* Images ----------------------------------------------------------------- */
.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.alignleft {
  margin: 12px 24px 12px 0;
}

img.alignright {
  margin: 12px 0 12px 24px;
}

img.aligncenter {
  margin-top: 12px;
  margin-bottom: 12px;
}

embed,
iframe,
object,
video {
  margin-bottom: 24px;
  max-width: 100%;
}

p > embed,
p > iframe,
p > object,
span > embed,
span > iframe,
span > object {
  margin-bottom: 0;
}

/* Input fields */
input,
textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  color: #2b2b2b;
  padding: 8px 10px 7px;
}

textarea {
  width: 100%;
}

input:focus,
textarea:focus {
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: 0;
}

/* Buttons */
button,
.contributor-posts-link,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: #00A0DC;
  border: 0;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  padding: 8px 20px 9px;
  text-transform: uppercase;
  vertical-align: bottom;
}

button:hover,
button:focus,
.contributor-posts-link:hover,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="reset"]:hover,
input[type="reset"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus {
  background-color: #11BDFF;
  color: #fff;
}

button:active,
.contributor-posts-link:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
  background-color: #11BDFF;
}

/* Placeholder text color -- selectors need to be separate to work. */
::-webkit-input-placeholder {
  color: #939393;
}

:-moz-placeholder {
  color: #939393;
}

::-moz-placeholder {
  color: #939393;
  opacity: 1;
  /* Since FF19 lowers the opacity of the placeholder by default */
}

:-ms-input-placeholder {
  color: #939393;
}

/* =Print
----------------------------------------------- */
@media print {
  body {
    background: none !important;
    color: #000;
    font-size: 10pt;
  }
  a[rel=bookmark]:link:after,
  a[rel=bookmark]:visited:after {
    content: " [" attr(href) "] ";
    /* Show URLs */
  }
  a {
    text-decoration: none;
  }
}
